<template>
  <v-container id="accounts" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate>
            <md-card-content class="custom-card-cont">
              <v-card class="card-cont">
                <v-row
                  class="account-search-bar"
                  justify="space-between"
                  align="center"
                >
                  <v-col cols="4" class="text-field-cont">
                    <v-text-field
                      v-model="search"
                      label="Company Name"
                      @input="debounceSearch"
                    />
                  </v-col>
                  <v-col cols="4" class="btn-cont">
                    <v-btn color="primary" @click="navigateToGlobalList">
                      Add to global list
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </md-card-content>
          </form>
          <v-row class="header-container">
            <v-col cols="4" class="text-element-2">Company Name</v-col>
            <v-col cols="3">Email</v-col>
            <v-col cols="3">Requested Time</v-col>
          </v-row>
          <div v-if="accounts.length">
            <v-card
              class="card-cont"
              v-for="account in accounts"
              :key="account.uuid"
            >
              <v-row class="account-container">
                <v-col cols="4" class="text-element">
                  <span>{{ account.organization_name }}</span>
                </v-col>
                <v-col cols="3">
                  <span>{{ account.requester_email }}</span>
                </v-col>
                <v-col cols="3">
                  <span>{{
                    new Date(account.created_at).toLocaleString()
                  }}</span>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    @click="openAddForm(account)"
                    color="primary"
                    class="action"
                  >
                    <v-icon>mdi-import</v-icon>
                  </v-btn>
                  <v-btn
                    @click="handleAccountClick(account)"
                    color="#70d0ce"
                    class="action"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <v-card v-else class="card-cont">
            No account requests Available
          </v-card>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <Pagination
            style="margin-right: -30px"
            :current-page="currentPage"
            :total-count="totalCount"
            @load-page="loadPage"
            v-if="accounts.length"
          />
        </v-container>
      </v-col>
    </v-row>
    <template>
      <v-dialog
        v-model="dialogVisible"
        max-width="600"
        content-class="v-dialog-custom"
      >
        <v-card>
          <v-card-title>
            <p class="display-2 black--text underline text-left">
              Account details
            </p>
          </v-card-title>
          <v-divider />
          <v-card-text v-if="selectedAccount" class="pt-0">
            <v-container class="py-0">
              <v-form ref="AccountDetails" class="mt-5">
                <v-row>
                  <v-col cols="12" md="12">
                    <div class="detail-row">
                      <div class="label">Company Name:</div>
                      <div class="data">
                        {{ selectedAccount.organization_name }}
                      </div>
                    </div>
                    <div class="detail-row">
                      <div class="label">Requester Name:</div>
                      <div class="data">
                        {{ selectedAccount.requester_name }}
                      </div>
                    </div>
                    <div class="detail-row">
                      <div class="label">Requester Email:</div>
                      <div class="data">
                        {{ selectedAccount.requester_email }}
                      </div>
                    </div>
                    <div class="detail-row">
                      <div class="label">Message:</div>
                      <div
                        class="data"
                        v-bind:style="{
                          display: 'flex',
                          flexDirection: 'column',
                        }"
                      >
                        {{ selectedAccount.message }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="deleteSelectedAccount(true)"
              color="error"
              dark
              class="delete-btn"
            >
              Delete
            </v-btn>
            <v-btn
              @click="dialogVisible = false"
              class="md-raised md-primary"
              style="background-color: #582963; color: #eef0fb"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- add to global list form -->
    <template>
      <popup-window
        :active="addItemForm"
        :title="'Add items to global list'"
        :hasBack="false"
        @popup-close="close"
        @popup-back="close"
      >
        <div>
          <div>
            <v-form ref="accountForm" class="mt-5">
              <div>
                <v-text-field
                  v-model="model.name"
                  label="Company Name"
                  outlined
                  clearable
                  :rules="[rules.required, rules.maxLength]"
                  dense
                />
              </div>
              <div>
                <v-select
                  v-model="model.type"
                  outlined
                  :items="accountFilterOptions"
                  label="Type"
                  :rules="[rules.required]"
                  dense
                ></v-select>
              </div>
            </v-form>
          </div>
          <div>
            <ui-button class="primary long" @click="addToGlobalList" title="Submit" />
          </div>
        </div>
      </popup-window>
    </template>
  </v-container>
</template>

<script>
import Pagination from "../../components/Pagination";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import debounce from "lodash/debounce";
import routes from "../../router/routes";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      dialogVisible: false,
      selectedAccount: null,
      selected: [],
      search: "",
      accountFilter: null,
      verificationFilter: null,
      loading: false,
      accounts: [],
      selectedHeader: "",
      accountFilterOptions: [
        { text: "Brand", value: 1 },
        { text: "Supplier", value: 2 },
      ],
      verificationFilterOptions: [
        { text: "Verified", value: 1 },
        { text: "Unverified", value: 0 },
      ],
      addItemForm: false,
      model: {
        name: null,
        type: null,
      },
      rules: {
        required: (value) => !!value || "This field is Required.",
        maxLength: (value) =>
          (value && value.length <= 255) || "Maximum 255 characters is allowed",
      },
    };
  },
  async mounted() {
    await this.fetchAccounts();
  },
  methods: {
    async fetchAccounts() {
      this.loading = true;

      let query = `?page=${this.currentPage}`;

      if (this.search) {
        query += `&query=${this.search}`;
      }

      const response = await restAdapter.get(
        "/api/organization_requests" + query
      );

      if (response.data) {
        this.accounts = response.data.data;
        this.totalCount = response.data.total;
      } else {
        this.selected = [];
        this.accounts = [];
      }
      this.loading = false;
    },
    async deleteSelectedAccount(byDeleteBtn) {
      if(byDeleteBtn){
        const confirmed = confirm(
        "Are you sure you want to delete this request?"
        );
        if (!confirmed) {
          return;
        }
      }      
      try {
        if (this.selectedAccount) {
          await restAdapter.delete(`/api/organization_requests/${this.selectedAccount.id}`)
          .then(() => {
            if(byDeleteBtn){
              notification.success("Account deleted successfully");
              this.dialogVisible = false;
            }
            this.fetchAccounts();
          });
        }
      } catch (error) {
        notification.error(
          error.message || "An error occurred while deleting the account"
        );
      }
    },
    debounceSearch: debounce(async function () {
      this.currentPage = 1;
      await this.fetchAccounts();
    }, 200),
    onFiltersChange() {
      this.currentPage = 1;
      this.fetchAccounts();
    },
    loadPage(page) {
      this.currentPage = page;
      this.fetchAccounts();
    },
    handleAccountClick(account) {
      this.selectedAccount = account;
      this.dialogVisible = true;
    },
    navigateToGlobalList() {
      this.$router.push({ name: routes.GLOBAL_ACCOUNT_LIST });
    },
    openAddForm(account) {
      this.selectedAccount = account;
      this.model.name = this.selectedAccount.organization_name;
      this.addItemForm = true;
    },
    addToGlobalList(){
      if (!this.$refs.accountForm.validate()) {
        return;
      }
      const formData = new FormData();
      formData.append("name", this.model.name);
      formData.append("type", this.model.type);

      restAdapter
        .post("/api/organization_registry", formData)
        .then((response) => {
          notification.successWithAlert("Account added successfully");
          this.deleteSelectedAccount(false);
          this.close();
          this.fetchAccounts();
        })
        .catch((error) => {
          notification.error("Something went wrong, please try again later");
        });
      this.addItemForm = false;
    },
    close() {
      this.addItemForm = false;
      this.model.name = null;
      this.model.type = null;
      this.$refs.accountForm.resetValidation();
    },
    
  },
};
</script>

<style>
.text-element-5 {
  text-align: right;
  padding-right: 25px;
}
.verification-btn {
  width: 100px;
}
.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}
.verification-success {
  background-color: #64d5ca;
}
.verification-error {
  background-color: #f66d9b;
}
.style-paginate {
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: -20px;
}
.account-align {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #473068;
}
.text-field-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.title-underline {
  border-bottom: 1px solid #70d0ce;
  width: fit-content;
}
.popup-card {
  padding: 20px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.centered-content {
  margin: 0 auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}

.right-top-corner {
  position: absolute;
  top: 5;
  right: 0;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  flex: 1;
  font-weight: bold;
  text-align: left;
}

.separator {
  margin: 0 10px;
  text-align: center;
}

.data {
  flex: 2;
  text-align: left;
}

.center-aligned {
  text-align: center;
  margin-top: 20px;
}
.account-search-bar {
  background: #ffffff;
  border-radius: 7px;
}
.btn-cont {
  text-align: right;
}
.card-cont {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}
.v-card-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.delete-btn {
  background-color: #e53935;
}

.md-raised {
  margin-top: 3px;
  background-color: #582963;
}
.label,
.separator,
.data {
  flex: 1;
}

.detail-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}
.header-container {
  color: #442d65;
  font-weight: 600;
  font-size: 16px;
  margin: -10px 0px -30px 0px;
  text-align: left;
}
.account-container {
  align-items: center;
  color: #442d65;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  background: #ffffff;
  text-align: left;
}
.custom-text-field ::v-deep label {
  color: #442d65;
}
.custom-card-cont {
  margin: 0px -15px;
}
.text-element-btn {
  justify-content: center;
  display: flex;
  align-items: center;
}
.text-element-1 {
  padding-left: 0px;
}
.text-element-2 {
  padding-left: 4px;
}
.theme--light.v-input {
  margin-right: 20px;
}
.theme--light.v-label {
  color: #442d65 !important;
}
.mdi-magnify::before {
  color: #442d65 !important;
}
.mdi-magnify::after {
  color: #442d65 !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #442d65 !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #442d65;
  border-color: #442d65;
  border-style: solid;
  border-width: thin 0 thin 0;
}
.action {
  width: 10px !important;
  /* border-radius: 40%; */
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}
.v-menu__content {
  z-index: 250 !important;
}
</style>
